import React from 'react';
import Meta from '../components/meta';
import ModulesController from '../components/modules-controller';
import { useContent } from '../hooks/use-content';
import { PageData } from '../types/global.types';
import { IPage } from '../types/heartcore.types';

const PageWithNavigation = ({ pageContext }:PageData<IPage>) => {
	const { meta, modules } =  useContent<IPage>(pageContext);

	return (
		<>
			<Meta {...meta} />
			<ModulesController modules={modules} />
		</>
	);
};

export default PageWithNavigation;
